//
// TikTokScraper (https://rapidapi.com/tikwm-tikwm-default/api/tiktok-scraper7)
//

import { FetchingStrategy, TTQueryTypes, TT_QUERY_TYPES, TiktokProfileProps, TiktokQueryProps, RAPIDAPI_KEY, StrategyName } from "..";

export default class TikTokScraper implements FetchingStrategy {
    public readonly strategyName: StrategyName = 'TikTokScraper';

    async fetchProfile(username: string, includePosts: boolean): Promise<any> {
        return { strategyName: this.strategyName, result: false }
    }

    async fetchUsernameByTiktokID(tiktokId: string): Promise<string | boolean> {
        ;
        const response = await fetch(`https://tiktok-scraper7.p.rapidapi.com/user/info?user_id=${tiktokId}`, {
            headers: {
                "X-RapidAPI-Key": RAPIDAPI_KEY,
                "X-RapidAPI-Host": "tiktok-scraper7.p.rapidapi.com"
            },
        });
        const data = await response.json();

        if (data.code === 0 && data.msg === "success" && data.data && data.data.user) {
            return data.data.user.uniqueId;
        } else {
            return false;
        }
    }

    async fetchTiktokQuery(query: string, queryType: string, limit: number = 15, attemptNumber: number = 0, backup: boolean = false): Promise<boolean | TiktokProfileProps | [TiktokQueryProps] | { error: string }> {
        let endpoint: string;
        switch (queryType) {
            case TT_QUERY_TYPES.hashtags:
                endpoint = `https://tiktok-scraper7.p.rapidapi.com/challenge/search?keywords=${query}&count=${limit}&cursor=0`;
                break;
            case TT_QUERY_TYPES.users:
                endpoint = `https://tiktok-scraper7.p.rapidapi.com/user/search?keywords=${query}&count=${limit}&cursor=0`;
                break;
            default:
                return false;
        }
        const response = await fetch(endpoint, {
            headers: {
                "X-RapidAPI-Key": RAPIDAPI_KEY,
                "X-RapidAPI-Host": "tiktok-scraper7.p.rapidapi.com"
            },
        });
        const data = await response.json();
        console.log(data);
        return this.processResponse("fetchTiktokQuery", data);
    }

    async fetchSimilarTiktokAccounts(tiktokId: string, limit: number = 15): Promise<boolean | TiktokProfileProps | [TiktokQueryProps] | { error: string }> {
        return false;
    }

    async fetchUserFollowers(tiktokId: string, limit: number = 15): Promise<boolean | TiktokProfileProps | [TiktokQueryProps] | { error: string }> {
        const response = await fetch(`https://tiktok-scraper7.p.rapidapi.com/user/followers?user_id=${tiktokId}&count=${limit}&time=0`, {
            headers: {
                "X-RapidAPI-Key": RAPIDAPI_KEY,
                "X-RapidAPI-Host": "tiktok-scraper7.p.rapidapi.com"
            },
        });
        const data = await response.json();
        console.log(data);
        return this.processResponse("fetchUserFollowers", data);
    }

    async processResponse(caller: string, response: any, queryType?: TTQueryTypes): Promise<[TiktokQueryProps] | TiktokProfileProps | { error: string } | boolean> {
        let results: TiktokProfileProps | [TiktokQueryProps] | { error: string } | boolean;

        if (response.code !== 0 || response.msg !== "success")
            throw new Error(`Failed to fetch data from TikTokScraper: ${JSON.stringify(response)}`);

        if (caller == "fetchProfile") {
            if (!response || !response.data || !response.data.user || !response.data.user.id) {
                // Received null user object, so the username most likely doesn't exist
                console.log(`Username doesn't exist.`);
                return false;
            }

            results = {
                id: response.data.user.uid,
                username: response.data.user.unique_id,
                full_name: response.data.user.nickname,
                biography: response.data.user.signature,
                profile_pic_url: `https://p16.tiktokcdn.com/aweme/720x720/${response.data.user.avatar_uri}`,
                profile_pic_url_hd: `https://p16.tiktokcdn.com/aweme/1080x1080/${response.data.user.avatar_larger.uri}`,
                follower_count: response.data.user.follower_count,
                following_count: response.data.user.following_count,
                likes_count: response.data.user.total_favorited,
                is_verified: response.data.user.verification_type && response.data.user.verification_type == 1,
            };

        } else if (caller == "fetchTiktokQuery") {
            // Fetching TikTok query
            response = response.data;
            if (response) {
                if (response.user_list && Array.isArray(response.user_list)) {
                    results = response.user_list.map((user: any) => ({
                        id: user.user.id,
                        username: user.user.uniqueId,
                        full_name: user.user.nickname,
                        biography: user.user.signature,
                        profile_pic_url: user.user.avatarThumb,
                        profile_pic_url_hd: user.user.avatarLarger,
                        is_private: user.user.privateAccount,
                        is_verified: user.user.verified,
                        follower_count: user.stats.followerCount,
                        following_count: user.stats.followingCount,
                        media_count: user.stats.videoCount,
                        likes_count: user.stats.heartCount,
                    }));
                } else if (response.challenge_list && Array.isArray(response.challenge_list)) {
                    // It's hashtag query
                    results = response.challenge_list.map((hashtag: any) => ({
                        target_id: hashtag.id,
                        name: hashtag.cha_name,
                        description: hashtag.desc,
                        userCount: hashtag.user_count,
                        viewCount: hashtag.view_count,
                    }));
                }
            }
        } else if (caller == "fetchUserFollowers") {
            if (response.data && response.data.followers && Array.isArray(response.data.followers)) {
                results = response.data.followers.map((follower: any) => ({
                    id: follower.id,
                    username: follower.unique_id,
                    full_name: follower.nickname,
                    biography: follower.signature,
                    profile_pic_url: follower.avatar,
                    is_private: follower.secret,
                    is_verified: follower.verified,
                    follower_count: follower.follower_count,
                    following_count: follower.following_count,
                    likes_count: follower.total_favorited,
                }));
            } else {
                results = false;
            }
        }
        return results;
    }
}