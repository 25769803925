import urlJoin from 'url-join';
import { redisClient } from '../redis';
import TikTok82 from './providers/TikTok82';
import TikTokBestExperience from './providers/TikTokBestExperience';
import TikTokScraper from './providers/TikTokScraper';

export const RAPIDAPI_KEY = "d4ee58a8c6mshf0a1a179a3ad022p107414jsn0c4b4e768319"
export const TT_QUERY_TYPES = {
	profile: 'profile',
	users: 'users',
	hashtags: 'hashtags',
	posts: 'posts',
	similarUsers: 'similarUsers',
	userFollowers: 'userFollowers',
} as const;
export type TTQueryTypes = typeof TT_QUERY_TYPES[keyof typeof TT_QUERY_TYPES];

export type TiktokProfileProps = {
	id: string,
	username: string,
	full_name?: string,
	biography?: string,
	category?: string,
	external_url?: string,
	external_url_title?: string,
	profile_pic_url?: string,
	profile_pic_url_hd?: string,
	follower_count?: number,
	following_count?: number,
	likes_count?: number,
	media_count?: number,
	is_private?: boolean,
	is_verified?: boolean,
	is_business?: boolean,
	posts?: Array<TiktokPostProps>,
	// is_professional?: boolean,
	// edge_followed_by?: any,
	// edge_follow?: any,
	// edge_owner_to_timeline_media?: Array<TiktokPostProps>/
} | false;

export type TiktokHashtagQueryProps = {
	target_id: string,
	name: string,
	description?: string,
	userCount?: number,
	viewCount?: number,
};

export type TiktokUserQueryProps = {
	full_name: string,
	avatar_url: string,
	profile_pic_url: string,
	username: string,
	is_verified: boolean,
	is_private: boolean,
	link: string,
	type: string,
	user_id: string,
	follower_count?: number
}

export type TiktokQueryProps = TiktokHashtagQueryProps | TiktokUserQueryProps;

export type TiktokPostProps = {
	id: string,
	caption: string,
	caption_language?: string,
	created_at: number,
	cover_image_url: string,
	duration: number,
	video_url: string,
	like_count: number,
	comment_count: number,
	play_count: number,
	bookmark_count: number,
	share_count: number,
	download_count: number,
	allow_share?: boolean,
	allow_comment?: boolean,
	allow_duet?: boolean,
	allow_react?: boolean,
	allow_stitch?: boolean,
	music?: {
		id: string,
		title: string,
		authorName: string,
		playUrl: string | null,
		duration: number | null,
		is_original: boolean | null,
	},
	author: {
		id: string,
		uniqueId: string,
		nickname: string,
		avatarThumb: string
	}
};

export type TiktokAnalyticsProps = {
	engagement_rate: number,
	follower_to_following_ratio: number,
	avg_likes_per_post: number,
	avg_comments_per_post: number,
	like_to_followers_ratio: number,
	like_to_comment_ratio: number,
	comment_to_followers_ratio: number,
	best_time_to_post: string,
	worst_time_to_post: string,
	most_successful_post: string,
	least_successful_post: string,
	top_5_interests: {
		[key: string]: number
	},
	top_5_countries: {
		[key: string]: number
	},
	top_5_traffic_sources: {
		[key: string]: number
	},
	age_groups: {
		[key: string]: number
	},
	categories: string[]
};

export interface FetchingStrategy {
	strategyName: StrategyName;
	fetchProfile(username: string, includePosts: boolean): Promise<any>;
	fetchUsernameByTiktokID(tiktokId: string): Promise<string | boolean>;
	fetchTiktokQuery(query: string, queryType: TTQueryTypes, limit?: number, attemptNumber?: number, backup?: boolean): Promise<any>;
	fetchSimilarTiktokAccounts(tiktokId: string, limit?: number, attemptNumber?: number, backup?: boolean): Promise<any>;
	fetchUserFollowers(userTiktokId: string, limit?: number): Promise<boolean | TiktokProfileProps | [TiktokQueryProps] | { error: string }>;
	processResponse(caller: string, response: any, queryType?: string, includePosts?: boolean): Promise<any>;
}

export type StrategyName = 'TikTok82' | 'TikTokBestExperience' | 'TikTokScraper';
const strategies = [
	new TikTokBestExperience(),
	new TikTok82(),
	new TikTokScraper(),
];

async function cacheOrFetch(redisKey: string, queryType: TTQueryTypes, ignoreCache = false, fetchFunction: () => Promise<any>): Promise<any> {
	let data: any;
	if (!ignoreCache) {
		const cachedData = await redisClient.get(redisKey);
		if (cachedData)
			return cachedData;
	}
	data = await fetchFunction();
	if (typeof data === "boolean")
		return data; // If data is of type boolean, it means that the query returned no results

	if (!data)
		throw new Error(`No data fetched for query: ${redisKey}`);

	// Cache for 6 hours for users, 30 days for hashtags and places, and forever for profiles
	const expiration = queryType === TT_QUERY_TYPES.users ? 6 * 60 * 60 : queryType === TT_QUERY_TYPES.profile ? -1 : 30 * 24 * 60 * 60;
	try {
		if (expiration === -1) {
			await redisClient.set(redisKey, JSON.stringify(data));
		} else {
			await redisClient.setex(redisKey, expiration, JSON.stringify(data));
		}
	} catch (error) {
		console.error("Error saving data in Redis: ", error);
	}
	return data;
}

function getNextStrategy(strategyIndex: number): { strategy: FetchingStrategy; nextIndex: number } {
	const nextIndex = (strategyIndex + 1) % strategies.length;
	return { strategy: strategies[nextIndex], nextIndex };
}

async function fetchData(config: { redisKey?: string; maxRetries: number; fetchFunction: (strategy: FetchingStrategy) => Promise<any>; preferredStrategy?: FetchingStrategy, forcePreferredStrategy?: boolean }): Promise<any> {
    let attemptNumber = 0;
    let strategyIndex = -1;
    const errors: any[] = [];
    while (attemptNumber < config.maxRetries) {
        let strategy: FetchingStrategy | undefined;
        if (attemptNumber === 0 && config.preferredStrategy) {
            strategy = config.preferredStrategy;
        } else if (!config.forcePreferredStrategy) {
            const { strategy: nextStrategy, nextIndex } = getNextStrategy(strategyIndex);
            strategy = nextStrategy;
            strategyIndex = nextIndex;
        } else if (config.forcePreferredStrategy && config.preferredStrategy) {
            strategy = config.preferredStrategy;
        }

        console.log(`Attempt ${attemptNumber + 1}, Strategy:`, strategy?.strategyName || 'undefined');

        if (!strategy) {
            console.log(`No valid strategy found for attempt ${attemptNumber + 1}. Skipping...`);
            attemptNumber++;
            continue;
        }

        try {
            return await config.fetchFunction(strategy);
        } catch (error) {
            if (!error.message.includes("Skipping")) {
                console.log(`Error in attempt ${attemptNumber + 1}:`, error);
            }
            errors.push(`Strategy ${strategy.strategyName}: ${error.message}`);
            if (strategyIndex === 0 || config.preferredStrategy || config.forcePreferredStrategy) {
                attemptNumber += 1;
            }
        }
    }
    throw new Error(`Failed to fetch data after ${config.maxRetries} retries with all strategies. Errors: ${errors.join("; ")}`);
}

export class TikTok {
	public static async fetchTiktokProfile(
		username: string,
		includePosts = false,
		ignoreCache = false,
		preferredStrategy: FetchingStrategy | null = null,
		maxRetries = 3,
		forcePreferredStrategy = false
	): Promise<TiktokProfileProps | any> {
		const redisKey = `tt_profile_${includePosts ? "full_" : ""}${username.replaceAll(" ", "")}`;
		console.log(`[fetchTiktokProfile] redisKey: ${redisKey} includePosts: ${includePosts} ignoreCache: ${ignoreCache} preferredStrategy: ${preferredStrategy?.strategyName || 'null'} forcePreferredStrategy: ${forcePreferredStrategy}`);
		return await cacheOrFetch(redisKey, TT_QUERY_TYPES.profile, ignoreCache, async () => {
			return await fetchData({
				redisKey,
				maxRetries,
				preferredStrategy,
				forcePreferredStrategy,
				fetchFunction: async (strategy: FetchingStrategy) => {
					if (!strategy) {
						throw new Error(`Strategy is undefined.`);
					}
					console.log(`🔎 Fetching TikTok profile '${username}' using strategy: ${strategy.strategyName}`);
					console.log(`Include posts: ${includePosts}`);
					console.log(`Force preferred strategy: ${forcePreferredStrategy}, strategy: ${strategy.strategyName}`);
					if (strategy.strategyName === 'TikTokScraper') {
						console.log(`🔵 TikTokScraper does not support fetchProfile. Returning false.`);
						return { strategyName: 'TikTokScraper', result: false };
					}
					return await strategy.fetchProfile(username, includePosts);
				},
			});
		});
	}

	public static async fetchUsernameByTiktokID(
		tiktokId: string
	): Promise<string | boolean> {
		return await fetchData({
			maxRetries: 2,
			fetchFunction: async (strategy: FetchingStrategy) => {
				if (strategy.strategyName === 'TikTokBestExperience' || strategy.strategyName === 'TikTokScraper') {
					console.log(`🔎 Fetching TikTok username for ID '${tiktokId}' using ${strategy.strategyName}...`);
					return await strategy.fetchUsernameByTiktokID(tiktokId);
				}
				throw new Error(`🔵 Skipping ${strategy.strategyName} for fetchUsernameByTiktokID as it is not supported.`);
			},
		});
	}

	public static async fetchTiktokPostsByUsername(
		username: string,
		limit: number = 30,
		preferredStrategy: FetchingStrategy = null,
		ignoreCache = false,
		maxRetries = 3,
		forcePreferredStrategy = false
	): Promise<TiktokPostProps[]> {
		const redisKey = `tt_posts_${username}`;
		return await cacheOrFetch(redisKey, TT_QUERY_TYPES.posts, ignoreCache, async () => {
			return await fetchData({
				redisKey,
				maxRetries,
				preferredStrategy,
				forcePreferredStrategy,
				fetchFunction: async (strategy: FetchingStrategy) => {
					if (strategy.strategyName === 'TikTokBestExperience') {
						console.log(`🔎 Fetching TikTok posts for user '${username}' using ${strategy.strategyName}...`);
						return await (strategy as TikTokBestExperience).fetchTiktokPostsByUsername(username, limit);
					}
					throw new Error(`🔵 Skipping ${strategy.strategyName} for fetchTiktokPostsByUsername as it is not supported.`);
				},
			});
		});
	}

	public static async fetchTiktokQuery(
		query: string,
		queryType: TTQueryTypes,
		limit = 15,
		preferredStrategy: FetchingStrategy = null,
		ignoreCache = false,
		maxRetries = 3,
		backup = false,
		forcePreferredStrategy = false
	): Promise<[TiktokQueryProps] | { error: string }> {
		if (!TT_QUERY_TYPES[queryType])
			return { error: `Invalid query type: ${queryType}` };

		const redisKey = `tt_query_${queryType}_${query}`;
		return await cacheOrFetch(redisKey, queryType, ignoreCache, async () => {
			return await fetchData({
				redisKey,
				maxRetries,
				preferredStrategy,
				forcePreferredStrategy,
				fetchFunction: async (strategy: FetchingStrategy) => {
					if (
						// Skip TikTok82 if query, as it's not supported
						strategy.strategyName === 'TikTok82'
					) {
						throw new Error(`🔵 Skipping ${strategy.strategyName} for fetchTikTokQuery as it is not supported.`);
					}
					console.log(`🔎 Fetching ${queryType ? `${queryType}` : ""} TikTok query '${query}' on attempt #${strategies.indexOf(strategy) + 1} (${strategy.strategyName})...`);
					return await strategy.fetchTiktokQuery(query, queryType, limit, maxRetries, backup);
				},
			});
		});
	}

	public static async fetchSimilarTiktokAccounts(
		tiktokId: string,
		limit = 15,
		preferredStrategy: FetchingStrategy = null,
		ignoreCache = false,
		maxRetries = 3,
		backup = false,
		forcePreferredStrategy = false
	): Promise<[TiktokUserQueryProps] | { error: string }> {
		const redisKey = `tt_similar_for_${tiktokId}`;
		return await cacheOrFetch(redisKey, TT_QUERY_TYPES.users, ignoreCache, async () => {
			return await fetchData({
				redisKey,
				maxRetries,
				preferredStrategy,
				forcePreferredStrategy,
				fetchFunction: async (strategy: FetchingStrategy) => {
					// Only RocketAPI and IGFlashAPI support fetchSimilarInstagramAccounts
					// if (strategy.strategyName !== 'RocketAPI' && strategy.strategyName !== 'IGFlashAPI') {
					// 	console.log(`🔵 Skipping ${strategy.strategyName} for fetchSimilarInstagramAccounts as it is not supported.`);
					// 	throw new Error(`🔵 Skipping ${strategy.strategyName} for fetchSimilarInstagramAccounts as it is not supported.`);
					// }
					console.log(`🔎 Fetching similar TikTok accounts for '${tiktokId}' on attempt #${strategies.indexOf(strategy) + 1} (${strategy.strategyName})...`);
					return await strategy.fetchSimilarTiktokAccounts(tiktokId, limit, maxRetries, backup);
				},
			});
		});
	}

	public static async downloadTiktokAvatar(username: string, avatarUrl: string, supabaseClient: any, bucket: string = 'avatars', folder: string = ''): Promise<string> {
		// console.log(`🔎 Downloading TT avatar for '${username}'...`);
		const response = await fetch(avatarUrl as string, { redirect: 'follow' });
		const fileData = await response.arrayBuffer();
		const imagePath = folder ? `${folder}/${username}.jpg` : `${username}.jpg`;
		const supabaseFileUrl = urlJoin(
			process.env.NEXT_PUBLIC_SUPABASE_URL,
			`/storage/v1/object/public/${bucket}`,
			folder,
			imagePath
		);
		const { data, error } = await supabaseClient.storage.from(bucket).upload(imagePath, fileData, {
			upsert: true,
			contentType: 'image/jpeg',
		});
		if (error) {
			if (error.message.includes("The resource already exists"))
				return supabaseFileUrl;
			console.error(`Error uploading TikTok avatar for '${username}': ${error.message}`);
			throw new Error(error.message);
		}
		return supabaseFileUrl;
	}

	public static async fetchUserFollowers(userTiktokId: string, limit: number = 15, ignoreCache = false,): Promise<any> {
		const redisKey = `tt_followers_for_${userTiktokId}`;
		return await cacheOrFetch(redisKey, TT_QUERY_TYPES.userFollowers, ignoreCache, async () => {
			return await fetchData({
				redisKey,
				maxRetries: 3,
				fetchFunction: async (strategy: FetchingStrategy) => {
					// Only RocketAPI and IGFlashAPI support fetchUserFollowers
					// if (strategy.strategyName !== 'RocketAPI' && strategy.strategyName !== 'IGFlashAPI') {
					// 	// console.log(`🔵  Skipping ${strategy.strategyName} for fetchUserFollowers as it is not supported.`);
					// 	throw new Error(`🔵 Skipping ${strategy.strategyName} for fetchUserFollowers as it is not supported.`);
					// }
					console.log(`🔎 Fetching TikTok followers for '${userTiktokId}' on attempt #${strategies.indexOf(strategy) + 1} (${strategy.strategyName})...`);
					return await strategy.fetchUserFollowers(userTiktokId, limit);
				},
			});
		});
	}

	public static async fetchAllCachedTiktokProfiles(): Promise<any> {
		// Returns the usernames of all the cached TikTok profiles
		let cursor = 0;
		let keys = [];
		do {
			const res = await redisClient.scan(cursor, { match: 'tt_profile_*', count: 1000 });
			cursor = parseInt(res[0], 10);
			keys.push(...res[1]);
		} while (cursor !== 0);
		if (!keys || keys.length === 0) return [];
		const usernames = keys.map((key) => key.replace("tt_profile_full_", "").replace("tt_profile_", ""));
		return [...new Set(usernames)]; // Remove duplicates
	}

	public static async isTiktokProfileCached(username: string): Promise<boolean> {
		let cursor = 0;
		do {
			const res = await redisClient.scan(cursor, { match: `tt_profile_*${username}`, count: 1000 });
			cursor = parseInt(res[0], 10);
			const keys = res[1];
			if (keys.length > 0) return true;
		} while (cursor !== 0);
		return false;
	}

	public static decodeTiktokMediaURL(url: string): string {
		if (!url) {
			console.warn('Attempted to decode undefined TikTok media URL');
			return '';
		}
		try {
			const parsedUrl = new URL(url);
			if (parsedUrl.hostname.includes('p16')) {
				return url;
			}
			// If the hostname is tiktokcdn.com and subdomain is not exactly p16, change it to p16
			if (parsedUrl.hostname.endsWith('tiktokcdn.com') && parsedUrl.hostname !== 'p16.tiktokcdn.com') {
				parsedUrl.hostname = 'p16.tiktokcdn.com';
				return parsedUrl.toString();
			}
			return `https://p16.tiktokcdn.com/aweme/720x720/${url}`;
		} catch (error) {
			console.error('Error decoding TikTok media URL:', error);
			return url; // Return the original URL if parsing fails
		}
	}

}

