//
// TikTok82 (https://rapidapi.com/datauniverse/api/tiktok82)
//

import { FetchingStrategy, TTQueryTypes, TT_QUERY_TYPES, TiktokProfileProps, TiktokQueryProps, RAPIDAPI_KEY, StrategyName } from "..";

export default class TikTok82 implements FetchingStrategy {
    public readonly strategyName: StrategyName = 'TikTok82';

    async fetchProfile(username: string, includePosts: boolean): Promise<any> {
        const response = await fetch('https://tiktok82.p.rapidapi.com/getProfile?username=' + username, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-RapidAPI-Key': RAPIDAPI_KEY,
                'X-RapidAPI-Host': 'tiktok82.p.rapidapi.com'
            }
        });
        const data = await response.json();
        console.log(data);
        return this.processResponse("fetchProfile", data);
    }

    async fetchUsernameByTiktokID(tiktokId: string): Promise<string | boolean> {
        return false;
    }

    async fetchTiktokQuery(query: string, queryType: string, limit: number = 15, attemptNumber: number = 0, backup: boolean = false): Promise<boolean | TiktokProfileProps | [TiktokQueryProps] | { error: string }> {
        return false;
    }

    async fetchSimilarTiktokAccounts(tiktokId: string, limit: number = 15): Promise<boolean | TiktokProfileProps | [TiktokQueryProps] | { error: string }> {
        return false;
    }

    async fetchUserFollowers(tiktokId: string, limit: number = 15): Promise<boolean | TiktokProfileProps | [TiktokQueryProps] | { error: string }> {
        return false;
    }

    async processResponse(caller: string, response: any, queryType?: TTQueryTypes): Promise<[TiktokQueryProps] | TiktokProfileProps | { error: string } | boolean> {
        let results: TiktokProfileProps;

        if (response.message == "Page not found")
            return false; // Username not found, likely doesn't exist

        if (response.success !== true)
            throw new Error(`Failed to fetch data from TikTok82: ${JSON.stringify(response)}`);

        if (caller == "fetchProfile") {
            if (!response || !response.data || !response.data.user || !response.data.user.id) {
                // Received null user object, so the username most likely doesn't exist
                console.log(`Username doesn't exist.`);
                return false;
            }

            results = {
                id: response.data.user.id,
                username: response.data.user.uniqueId,
                full_name: response.data.user.nickname,
                biography: response.data.user.signature,
                // category: response.data.user.category || response.data.user.category_name,
                // external_url: response.data.user.external_url,
                // external_url_title: response.data.user.bio_links && response.data.user.bio_links.length && response.data.user.bio_links[0].title ? response.data.user.bio_links[0].title : null,
                profile_pic_url: response.data.user.avatarThumb,
                profile_pic_url_hd: response.data.user.avatarLarger,
                follower_count: response.data.stats.followerCount,
                following_count: response.data.stats.followingCount,
                media_count: response.data.stats.videoCount,
                likes_count: response.data.stats.heartCount,
                is_private: response.data.user.privateAccount,
                is_verified: response.data.user.verified,
                // is_business: response.data.user.is_business || response.data.user.is_business_account,
                // is_professional: response.data.user.is_professional || response.data.user.is_professional_account || false,
                // edge_followed_by: response.data.user.edge_followed_by,
                // edge_follow: response.data.user.edge_follow,
                // edge_owner_to_timeline_media: response.data.user.edge_owner_to_timeline_media.edges.map(edge => ({
                // 	id: edge.node.shortcode,
                // 	caption: edge.node.edge_media_to_caption.edges[0]?.node.text,
                // 	location: edge.node.location && edge.node.location.name ? edge.node.location.name : null,
                // 	image_url: edge.node.display_url,
                // 	hd_image_url: edge.node.thumbnail_resources[edge.node.thumbnail_resources.length - 1]?.src,
                // 	like_count: edge.node.edge_liked_by.count,
                // 	comment_count: edge.node.edge_media_to_comment.count,
                // 	taken_at: edge.node.taken_at_timestamp,
                // 	accessibility_caption: edge.node.accessibility_caption ? edge.node.accessibility_caption : null,
                // 	is_video: edge.node.is_video,
                // 	video_view_count: edge.node.video_view_count ? edge.node.video_view_count : null,
                // 	is_album: edge.node.edge_sidecar_to_children ? true : false,
                // 	album_children: edge.node.edge_sidecar_to_children && edge.node.edge_sidecar_to_children.edges && edge.node.edge_sidecar_to_children.edges.length ? edge.node.edge_sidecar_to_children.edges.map((child: any) => ({
                // 		id: child.node.shortcode,
                // 		image_url: child.node.display_url,
                // 		is_video: child.node.is_video,
                // 		video_url: child.node.video_url ? child.node.video_url : null,
                // 		video_view_count: child.node.video_view_count ? child.node.video_view_count : null,
                // 		accessibility_caption: child.node.accessibility_caption ? child.node.accessibility_caption : null,
                // 	})) : null,
                // }))
            };

        } else if (caller == "fetchSimilarTiktokAccounts") {
            if (response.status === "OK" && response.data && response.data.user && response.data.user.edge_chaining && response.data.user.edge_chaining.edges && response.data.user.edge_chaining.edges.length) {
                results = response.data.user.edge_chaining.edges.map((edge: any) => ({
                    id: edge.node.id,
                    full_name: edge.node.full_name,
                    is_verified: edge.node.is_verified,
                    profile_pic_url: edge.node.profile_pic_url,
                    username: edge.node.username,
                    is_private: edge.node.is_private,
                }));
            }
        } else if (caller == "fetchUserFollowers") {
            if (response.status === "OK" && response.users && response.users.length) {
                results = response.users.map((user: any) => ({
                    full_name: user.full_name,
                    avatar_url: user.profile_pic_url,
                    profile_pic_url: user.profile_pic_url,
                    username: user.username,
                    is_verified: user.is_verified,
                    is_private: user.is_private,
                    user_id: user.pk,
                }));
            }
        }
        return results;
    }
}