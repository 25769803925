import { AppSupabaseClient, AuthProvider, Table } from '@/types';
import { ROOT_DOMAIN, DEMO_ACCOUNT_IDS, targetingOptionsDefault, PROTOCOL, ROOT_URL_PATH } from '@/constants';
import { User } from '@supabase/supabase-js';
import { errors } from './errors';
import { TiktokProfileProps } from '@shared-libs/tiktok';


export const getActiveProductsWithPrices = async (supabase: AppSupabaseClient) => {
  const { data, error } = await supabase
    .from('products')
    .select('*, prices(*)')
    .eq('active', true)
    .eq('prices.active', true)
    .order('unit_amount', { foreignTable: 'prices' });

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return data || [];
};

export const updateUserName = async (supabase: AppSupabaseClient, user: User, name: string) => {
  await supabase
    .from('user_profiles')
    .update({ full_name: name })
    .eq('id', user.id);
};

export const getUserProfile = async (supabase: AppSupabaseClient, userId: string): Promise<Table<'user_profiles'>> => {
  const { data, error } = await supabase
    .from('user_profiles')
    .select('*')
    .eq('id', userId)
    .limit(1);

  // console.log('🚀 ~ file: supabase-queries.ts - getUserProfile() ~ data', data, 'userId:', userId)
  if (error) {
    console.error(error);
    errors.add(error.message);
    throw error;
  }
  return data ? data[0] : null;
};


export const getUserByCustomerId = async (supabase: AppSupabaseClient, customerId: string) => {
  const { data, error } = await supabase
    .from('user_profiles')
    .select('*')
    .eq('customer_id', customerId)
    .limit(1)
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return data;
};

export const updateUserProfileNameAndAvatar = async (
  supabase: AppSupabaseClient,
  userId: string,
  {
    fullName,
    avatarUrl,
    email,
  }: {
    fullName?: string;
    avatarUrl?: string;
    email?: string;
  }
) => {
  const { data, error } = await supabase
    .from('user_profiles')
    .update({
      full_name: fullName,
      avatar_url: avatarUrl,
      email: email,
    })
    .eq('id', userId)
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return data;
};

export const updateUserProfile = async (
  supabase: AppSupabaseClient,
  userId: string,
  data: {
    full_name?: string;
    avatar_url?: string;
    email?: string;
    traffic_source?: string;
    enabled?: boolean;
    customer_id?: string;
    user_info?: any;
  }
) => {
  const { data: updatedData, error } = await supabase
    .from('user_profiles')
    .update({
      ...(data.full_name && { full_name: data.full_name }),
      ...(data.avatar_url && { avatar_url: data.avatar_url }),
      ...(data.email && { email: data.email }),
      ...(data.traffic_source && { traffic_source: data.traffic_source }),
      ...(data.enabled !== undefined && { enabled: data.enabled }),
      ...(data.customer_id && { customer_id: data.customer_id }),
      ...(data.user_info && { user_info: data.user_info }),
    })
    .eq('id', userId)
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return updatedData;
};


/* ==================== */
/* Accounts */
/* ==================== */

export const createAccount = async (supabase: AppSupabaseClient, user: User, tiktok_profile_data: TiktokProfileProps | false) => {
  console.log('🚀 ~ file: supabase-queries.ts:93 ~ user', user);

  // // Make a call to /download-avatar API endpoint to download Instagram profile picture and upload to Supabase Storage
  // const response = await fetch(`/api/accounts/download-avatar?avatarUrl=${encodeURIComponent(Instagram.decodeInstagramMediaURL(tiktok_profile_data.graphql.user.profile_pic_url))}&userId=${user.id}&username=${tiktok_profile_data.graphql.user.username}`);
  // const { fileUrl: instagramSupabaseAvatarUrl } = await response.json();
  // console.log("Avatar URL: ", instagramSupabaseAvatarUrl)
  if (!tiktok_profile_data)
    throw new Error('Invalid TikTok profile data');

  const db_account:any = {
    username: tiktok_profile_data.username,
    account_type: 'trial',
    name: tiktok_profile_data.full_name,
    tiktok_id: tiktok_profile_data.id,
    followers: tiktok_profile_data.follower_count,
    starting_followers: tiktok_profile_data.follower_count,
    followings: tiktok_profile_data.following_count,
    likes: tiktok_profile_data.likes_count,
    starting_likes: tiktok_profile_data.likes_count,
    medias: tiktok_profile_data.media_count,
    conversions: 0,
    actions: 0,
    user_id: user.id,
    active: false,
    connected: true,
    avatar_url: `https://db.okgrow.com/storage/v1/object/public/avatars/${tiktok_profile_data.username}.jpg`,
    targeting_options: JSON.stringify(targetingOptionsDefault),
    created_at: new Date().toISOString(),
    // ...(tiktok_profile_data.themeColor && { theme_color: tiktok_profile_data.themeColor })
  }

  // Insert account in db
  const { data, error } = await supabase
    .from('accounts')
    .insert(db_account)
    .select('id')
    .single();

  if (error) {
    console.error(error);
    console.error(error.code);

    if (error.code == '23505') {
      const error = new Error(String('This TikTok profile is already connected to an OkGrow account.'));
      errors.add(error.message);
      throw error;
    } else 
      errors.add(error.message);

    throw error;
  }

  return data;
};

/* ==================== */
/* Accounts */
/* ==================== */

export async function getUserHasAccounts(supabaseClient: AppSupabaseClient, userId: string): Promise<boolean> {
  const { data: userHasAccounts, error } = await supabaseClient
    .rpc('check_if_user_has_accounts', {
      user_id: userId,
    })
    .single();
  if (error) {
    console.error(error);
    throw error;
  }
  return Boolean(userHasAccounts);
}

export async function getUserHasUpgradableAccounts(supabaseClient: AppSupabaseClient, userId: string): Promise<boolean> {
  const { data: userHasAccounts, error } = await supabaseClient
    .rpc('check_if_user_has_upgradable_accounts', {
      user_id: userId,
    })
    .single();
  if (error) {
    console.error(error);
    throw error;
  }
  return Boolean(userHasAccounts);
}

export const getAllAccountsForUser = async (supabase: AppSupabaseClient) => {
  const { data, error } = await supabase
    .from('accounts')
    .select('*')
    .neq('account_type', 'archived')

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return data || [];
};

export const getAccountById = async (supabase: AppSupabaseClient, accountId: string) => {
  console.log('getAccountById', accountId);
  const { data, error } = await supabase
    .from('accounts')
    .select('*')
    .eq('id', accountId)
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }
  return data;
};

export const getAccountBySubscriptionId = async (supabase: AppSupabaseClient, subscription_id: string): Promise<Table<'accounts'>> => {
  const { data, error } = await supabase
    .from('accounts')
    .select('*')
    .eq('subscription_id', subscription_id)
    .limit(1)
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }
  return data;
};

export const getAccountByCustomerId = async (supabase: AppSupabaseClient, customer_id: string): Promise<Table<'accounts'>> => {
  const { data, error } = await supabase
    .from('accounts')
    .select('*')
    .eq('customer_id', customer_id)
    .limit(1)
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }
  return data;
};

export const getAccountByUsername = async (supabase: AppSupabaseClient, accountUsername: string): Promise<Table<'accounts'>> => {
  const { data, error } = await supabase
    .from('accounts')
    .select('*')
    .eq('username', accountUsername)
    .single();
  if (error) {
    errors.add(error.message);
    throw error;
  }
  return data;
};

export const getAllActivitiesForAccount = async (supabase: AppSupabaseClient, accountId: string, page: number = 0) => {
  const { data, error } = await supabase
    .from('activities')
    .select(`*,
      targets: targets!target_id (name, type),
      target_name
    `)
    .eq('account_id', accountId)
    .order('created_at', { ascending: false })
    .range(page * 10, (page + 1) * 10 - 1);

  if (error) {
    errors.add(error.message);
    throw error;
  }

  // Loop through activities and if activity.thumbnails is JSON string, parse it
  data.forEach((activity: any) => {
    if (activity.thumbnails && typeof activity.thumbnails === 'string') {
      activity.thumbnails = JSON.parse(activity.thumbnails);
    }
  });

  return data;
};

export const getAllUpdatesForAccount = async (supabase: AppSupabaseClient, accountId: string, fetchAllUpdates: boolean = false) => {
  let query = supabase
    .from('updates')
    .select('*')
    .eq('account_id', accountId)
    .order('created_at', { ascending: true });

  console.log('fetchAllUpdates', fetchAllUpdates);
  if (!fetchAllUpdates) {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    query = query.gte('created_at', thirtyDaysAgo.toISOString());
  }

  const { data, error } = await query;

  console.log('getAllUpdatesForAccount', data);

  if (error) {
    errors.add(error.message);
    throw error;
  }
  return data;
};

export const getAllTargetsForAccount = async (supabase: AppSupabaseClient, accountId: string) => {
  const { data, error } = await supabase
    .from('targets')
    .select('*')
    .eq('enabled', true)
    .eq('account_id', accountId)

  console.log('getAllTargetsForAccount', data);

  if (error) {
    errors.add(error.message);
    throw error;
  }
  return data;
};

export const addTargetForAccount = async (supabase: AppSupabaseClient, accountId: string, target: any) => {
  try {
    // Download the avatar url of the target, if IG User target
    let instagramSupabaseAvatarUrl;
    if (target.type == 'user' && target.thumbnail) {
      try {
        const response = await fetch(`${ROOT_URL_PATH}/api/tiktok/download-avatar`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            username: target.name,
            avatar_url: target.thumbnail,
          }),
        });
        const { avatar_url } = await response.json();
        instagramSupabaseAvatarUrl = avatar_url;
      } catch (error) {
        console.error('Error downloading Instagram avatar: ', error);
      }
    }

    // If it's a demo account, don't actually add to Supabase
    if (DEMO_ACCOUNT_IDS.includes(accountId)) {
      return {
        account_id: accountId,
        ...target,
        ...(instagramSupabaseAvatarUrl && { thumbnail: instagramSupabaseAvatarUrl }),
        id: Math.random().toString(36).substring(7),
        created_at: new Date().toISOString()
      };
    }

    const { data, error } = await supabase
      .from('targets')
      .insert({
        ...target,
        account_id: accountId,
        ...(instagramSupabaseAvatarUrl && { thumbnail: instagramSupabaseAvatarUrl }),
      })
      .select('*')
      .single();

    if (error) 
      throw error;

    return { account_id: accountId, ...data };
  } catch (error) {
    console.error('Error adding target for account:', error);
    throw error;
  }
}

export const removeTargetForAccount = async (supabase: AppSupabaseClient, accountId: string, targetId: string) => {

  // If it's a demo account, don't actually remove from Supabase
  if (DEMO_ACCOUNT_IDS.includes(accountId)) {
    return {
      account_id: accountId,
      targetId: targetId,
    };
  }

  const { data, error } = await supabase
    .from('targets')
    .update({ enabled: false })
    .eq('id', parseInt(targetId))
    .single();

  console.log('removeTargetForAccount', data);

  if (error) {
    errors.add(error.message);
    throw error;
  }
  return { account_id: accountId, targetId: targetId };
}

export const getLast30DaysUpdatesForAccount = async (supabase: AppSupabaseClient, accountId: string) => {
  const { data, error } = await supabase
    .from('updates')
    .select('*')
    .eq('account_id', accountId)
    .gte('created_at', new Date(new Date().setDate(new Date().getDate() - 30)))
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }
  return data;
};

export const updateAccount = async (supabase: AppSupabaseClient, accountId: string, updatedData: Partial<Table<'accounts'>>): Promise<Table<'accounts'>> => {
  const { data, error } = await supabase
    .from('accounts')
    .update(updatedData)
    .eq('id', accountId)
    .select('*')
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return data;
};

/* ==================== */
/* Organizations */
/* ==================== */

export const getAllOrganizationsForUser = async (supabase: AppSupabaseClient) => {
  const { data, error } = await supabase
    .from('organizations')
    .select(
      '*, organization_team_members(id,member_id,member_role, user_profiles(*)), subscriptions(id, prices(id,products(id,name)))'
    );
  if (error) {
    errors.add(error.message);
    throw error;
  }

  return data || [];
};

export const getOrganizationById = async (supabase: AppSupabaseClient, organizationId: string) => {
  const { data, error } = await supabase
    .from('organizations')
    // query team_members and team_invitations in one go
    .select('*')
    .eq('id', organizationId)
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return data;
};

export const createOrganization = async (supabase: AppSupabaseClient, user: User, name: string) => {
  const { data, error } = await supabase
    .from('organizations')
    .insert({
      title: name,
      created_by: user.id,
    })
    .select('*')
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return data;
};

export const updateOrganizationTitle = async (supabase: AppSupabaseClient, organizationId: string, title: string): Promise<Table<'organizations'>> => {
  const { data, error } = await supabase
    .from('organizations')
    .update({
      title,
    })
    .eq('id', organizationId)
    .select('*')
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return data;
};

export const getTeamMembersInOrganization = async (supabase: AppSupabaseClient, organizationId: string) => {
  const { data, error } = await supabase
    .from('organization_team_members')
    .select('*, user_profiles(*)')
    .eq('organization_id', organizationId);

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return data || [];
};

export const getPendingTeamInvitationsInOrganization = async (supabase: AppSupabaseClient, organizationId: string) => {
  const { data, error } = await supabase
    .from('organization_team_invitations')
    .select(
      '*, inviter:user_profiles!inviter_user_id(*), invitee:user_profiles!invitee_user_id(*)'
    )
    .eq('organization_id', organizationId)
    .eq('status', 'active');

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return data || [];
};

export const getOrganizationSubscription = async (supabase: AppSupabaseClient, organizationId: string) => {
  const { data, error } = await supabase
    .from('subscriptions')
    .select('*, prices(*, products(*))')
    .eq('organization_id', organizationId)
    .in('status', ['trialing', 'active'])
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return data;
};

export const getUserOrganizationRole = async (supabase: AppSupabaseClient, userId: string, organizationId: string) => {
  const { data, error } = await supabase
    .from('organization_team_members')
    .select('member_id, member_role')
    .eq('member_id', userId)
    .eq('organization_id', organizationId)
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return data;
};

/* ====================     */
/* Admin & Maintenance mode */
/* ====================     */

export async function getIsAppAdmin(supabaseClient: AppSupabaseClient, authUser: User): Promise<boolean> {
  const { data: isUserAppAdmin, error } = await supabaseClient
    .rpc('check_if_user_is_app_admin', {
      user_id: authUser.id,
    })
    .single();
  if (error) {
    throw error;
  }

  return Boolean(isUserAppAdmin);
}

export const getIsAppInMaintenanceMode = async (supabaseClient: AppSupabaseClient): Promise<boolean> => {
  const { data, error } = await supabaseClient
    .rpc('is_app_in_maintenance_mode')
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return Boolean(data);
};

/* ==================== */
/* AUTH */
/* ==================== */

export const signInWithMagicLink = async (
  supabase: AppSupabaseClient,
  email: string
) => {
  const protocol = process.env.NODE_ENV === 'production' ? 'https://' : 'http://';
  const { error } = await supabase.auth.signInWithOtp({
    email,
    options: {
      emailRedirectTo: `${protocol}${ROOT_DOMAIN}/auth/callback`,
    },
  });

  if (error) {
    errors.add(error.message);
    throw error;
  }
};

export const signInWithPassword = async (
  supabase: AppSupabaseClient,
  email: string,
  password: string
) => {
  const { error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (error) {
    errors.add(error.message);
    throw error;
  }
};

export const resetPassword = async (
  supabase: AppSupabaseClient,
  email: string
) => {
  const { error } = await supabase.auth.resetPasswordForEmail(email);

  if (error) {
    errors.add(error.message);
    throw error;
  }
};

export const updatePassword = async (
  supabase: AppSupabaseClient,
  password: string
) => {
  const { error } = await supabase.auth.updateUser({
    password,
  });

  if (error) {
    errors.add(error.message);
    throw error;
  }
};

export const signInWithProvider = async (
  supabase: AppSupabaseClient,
  provider: AuthProvider
) => {
  const { error } = await supabase.auth.signInWithOAuth({
    provider,
    options: {
      // redirectTo: `${PROTOCOL}${process.env.NODE_ENV === 'production' ? 'www.' : ''}${ROOT_DOMAIN}/auth/callback?provider=true`,
      redirectTo: `https://www.okgrow.com/auth/callback?provider=true`,
    },
  });

  if (error) {
    errors.add(error.message);
    throw error;
  }
};

export const signUp = async (
  supabase: AppSupabaseClient,
  email: string,
  password: string
) => {
    console.log(`🟡🟡🟡Signing up user with email: ${email}`)
    const protocol = process.env.NODE_ENV === 'production' ? 'https://' : 'http://';
    const { data:newUser, error: signUpError } = await supabase.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: `${protocol}${ROOT_DOMAIN}/auth/callback`,
      },
    });

    console.log("🟡  New user: ", newUser);

    // console.log("🟡  Sending notification: ", email);
    // console.log(newUser)
    // Notifications.sendNotification("signups", "New sign up", `New sign up from ${email}`, "🆕", true, email)

    if (signUpError) {
      errors.add(signUpError.message);
      console.error(signUpError);
      throw signUpError;
    }

    return newUser;


  // Create user_profile in supabase
  // const { error: insertError } = await supabase
  //   .from('user_profiles')
  //   .insert({
  //     id: email,
  //     full_name: '',
  //     avatar_url: '',
  //     email: email,
  //   });

  // if (insertError) {
  //   errors.add(insertError.message);
  //   throw insertError;
  // }

};
