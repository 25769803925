//
// TikTokBestExperience (https://rapidapi.com/ponds4552/api/tiktok-best-experience)
//

import { FetchingStrategy, TTQueryTypes, TT_QUERY_TYPES, TiktokProfileProps, TiktokQueryProps, RAPIDAPI_KEY, StrategyName, TiktokPostProps } from "..";

export default class TikTokBestExperience implements FetchingStrategy {
    public readonly strategyName: StrategyName = 'TikTokBestExperience';

    async fetchProfile(username: string, includePosts: boolean): Promise<any> {
        const response = await fetch('https://tiktok-best-experience.p.rapidapi.com/user/' + username, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-RapidAPI-Key': RAPIDAPI_KEY,
                'X-RapidAPI-Host': 'tiktok-best-experience.p.rapidapi.com'
            }
        });
        const data = await response.json();
        console.log(data);
        return this.processResponse("fetchProfile", data);
    }

    async fetchUsernameByTiktokID(tiktokId: string): Promise<string | boolean> {
        const response = await fetch(`https://tiktok-best-experience.p.rapidapi.com/user/id/${tiktokId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-RapidAPI-Key': RAPIDAPI_KEY,
                'X-RapidAPI-Host': 'tiktok-best-experience.p.rapidapi.com'
            }
        });
        const data = await response.json();
        console.log(data);

        if (data.status === "ok" && data.data && data.data.user && data.data.user.unique_id) {
            console.log(`🔎 Fetched TikTok username by ID: ${data.data.user.unique_id}`);
            return data.data.user.unique_id;
        } else {
            return false;
        }
    }
    async fetchTiktokPostsByUsername(username: string, limit: number = 30): Promise<TiktokPostProps[]> {
        let allPosts: TiktokPostProps[] = [];
        let hasMore = true;
        let currentCursor = '';

        function processPosts(posts: any[]): TiktokPostProps[] {
            return posts.map((post: any) => ({
                id: post.aweme_id,
                caption: post.desc,
                caption_language: post.desc_language,
                created_at: post.create_time,
                duration: post.video?.duration || 0,
                cover_image_url: post.video?.cover?.url_list?.[0] || '',
                video_url: post.video?.play_addr?.url_list?.[0] || '',
                like_count: post.statistics.digg_count,
                comment_count: post.statistics.comment_count,
                play_count: post.statistics.play_count,
                bookmark_count: post.statistics.collect_count,
                share_count: post.statistics.share_count,
                download_count: post.statistics.download_count,
                allow_share: post.status?.allow_share,
                allow_comment: post.status?.allow_comment,
                allow_duet: post.video_control?.allow_duet,
                allow_react: post.video_control?.allow_react,
                allow_stitch: post.video_control?.allow_stitch,
                music: post.music ? {
                    id: post.music.id || '',
                    title: post.music.title || '',
                    authorName: post.music.author || '',
                    playUrl: post.music.play_url?.url_list?.[0] || null,
                    duration: post.music.duration || null,
                    is_original: post.music.is_original_sound || null,
                } : null,
                author: post.author?.unique_id || '', // Returning just the username of the author to avoid large payloads
                // author: {
                //     id: post.author?.uid || '',
                //     uniqueId: post.author?.unique_id || '',
                //     nickname: post.author?.nickname || '',
                //     avatarThumb: post.author?.avatar_thumb?.url_list?.[0] || ''
                // },
            }));
        }

        while (hasMore && allPosts.length < limit) {
            console.log(`🔎 Fetching TikTok posts for ${username} with cursor ${currentCursor}`);
            const response = await fetch(`https://tiktok-best-experience.p.rapidapi.com/user/${username}/feed${currentCursor ? `?max_cursor=${currentCursor}` : ''}`, {
                method: 'GET',
                headers: {
                    'x-rapidapi-host': 'tiktok-best-experience.p.rapidapi.com',
                    'x-rapidapi-key': 'd4ee58a8c6mshf0a1a179a3ad022p107414jsn0c4b4e768319'
                }
            });
            const data = await response.json();
            console.log(`🔎 Fetched TikTok posts: ${JSON.stringify(data)}`);

            if (data.status !== 'ok') {
                throw new Error('Failed to fetch TikTok posts');
            }

            const newPosts = processPosts(data.data.aweme_list);
            allPosts = allPosts.concat(newPosts);

            hasMore = data.data.has_more === 1;
            currentCursor = data.data.max_cursor;

            if (!hasMore || allPosts.length >= limit) {
                break;
            }
        }

        // Remove duplicate posts and sort by created_at in descending order (newest first)
        allPosts = Array.from(new Map(allPosts.map(post => [post.id, post])).values())
            .sort((a, b) => b.created_at - a.created_at);

        return allPosts;
    }

    async fetchTiktokQuery(query: TTQueryTypes, queryType: string, limit: number = 15, attemptNumber: number = 0, backup: boolean = false): Promise<boolean | TiktokProfileProps | [TiktokQueryProps] | { error: string }> {
        // TikTokBestExperience only supports hashtag queries. Return false if it's not a hashtag query. Keep the switch statement.
        let endpoint: string;
        switch (queryType) {
            case TT_QUERY_TYPES.hashtags:
                endpoint = `https://tiktok-best-experience.p.rapidapi.com/search/challenge/${query}`;
                break;
            case TT_QUERY_TYPES.users:
                endpoint = `https://tiktok-best-experience.p.rapidapi.com/search/user/${query}`;
                break;
            default:
                return false;
        }
        const response = await fetch(endpoint, {
            headers: {
                "X-RapidAPI-Key": RAPIDAPI_KEY,
                "X-RapidAPI-Host": "tiktok-best-experience.p.rapidapi.com",
            },
        });
        const data = await response.json();
        console.log(data);
        try {
            return this.processResponse("fetchTiktokQuery", data, queryType);
        } catch (error) {
            console.error(`🚨 Error processing TikTok query response: ${error}`);
            throw error;
        }
    }

    async fetchSimilarTiktokAccounts(tiktokId: string, limit: number = 15): Promise<boolean | TiktokProfileProps | [TiktokQueryProps] | { error: string }> {
        return false;
    }

    async fetchUserFollowers(tiktokId: string, limit: number = 15): Promise<boolean | TiktokProfileProps | [TiktokQueryProps] | { error: string }> {
        const response = await fetch(`https://tiktok-best-experience.p.rapidapi.com/user/id/${tiktokId}/followers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-RapidAPI-Key': RAPIDAPI_KEY,
                'X-RapidAPI-Host': 'tiktok-best-experience.p.rapidapi.com'
            }
        });
        const data = await response.json();
        console.log(data);
        return this.processResponse("fetchUserFollowers", data, TT_QUERY_TYPES.userFollowers);
    }

    async processResponse(caller: string, response: any, queryType?: TTQueryTypes): Promise<[TiktokQueryProps] | TiktokProfileProps | { error: string } | boolean> {
        let results: TiktokProfileProps | [TiktokQueryProps] | { error: string } | boolean;

        if (response.message == "Page not found")
            return false; // Username not found, likely doesn't exist

        if (response.status !== "ok")
            throw new Error(`Failed to fetch data from TikTokBestExperience: ${JSON.stringify(response)}`);

        if (caller == "fetchProfile") {

            console.log(`🔎 Fetched TikTok profile: ${JSON.stringify(response)}`);
            if (!response || !response.data || !response.data.user || !response.data.user.uid) {
                // Received null user object, so the username most likely doesn't exist
                console.log(`Username doesn't exist.`);
                return false;
            }

            results = {
                id: response.data.user.uid,
                username: response.data.user.unique_id,
                full_name: response.data.user.nickname,
                biography: response.data.user.signature,
                profile_pic_url: response.data.user.avatar_medium?.url_list?.[0] || '',
                profile_pic_url_hd: response.data.user.avatar_larger?.url_list?.[0] || '',
                follower_count: response.data.user.follower_count,
                following_count: response.data.user.following_count,
                likes_count: response.data.user.total_favorited,
                media_count: response.data.user.aweme_count,
                is_verified: response.data.user.verification_type ? response.data.user.verification_type == 1 : false,
                is_private: response.data.user.privacy_setting?.following_visibility !== 1,
                // is_likes_private: response.data.user.tab_settings?.private_tab?.private_tab_style === 1 || false
            };
        } else if (caller == "fetchTiktokQuery") {
            // Fetched TikTok query
            response = response.data;
            switch (queryType) {
                case TT_QUERY_TYPES.hashtags:
                    if (response && response.challenge_list && Array.isArray(response.challenge_list)) {
                        // Is hashtag
                        results = response.challenge_list.map((hashtag) => ({
                            target_id: hashtag.challenge_info.cid,
                            name: hashtag.challenge_info.cha_name,
                            description: hashtag.challenge_info.desc,
                            userCount: hashtag.challenge_info.user_count,
                            viewCount: hashtag.challenge_info.view_count,
                        }));
                    }
                    break;
                case TT_QUERY_TYPES.users:
                    if (response && response.user_list && Array.isArray(response.user_list)) {
                        results = response.user_list.map((user) => ({
                            full_name: user.user_info.nickname,
                            avatar_url: user.user_info.avatar_medium.url_list[0],
                            profile_pic_url: user.user_info.avatar_larger.url_list[0],
                            username: user.user_info.unique_id,
                            is_verified: user.user_info.verification_type === 1,
                            is_private: false, // TikTok API doesn't provide this information
                            link: `https://www.tiktok.com/@${user.user_info.unique_id}`,
                            type: 'user',
                            user_id: user.user_info.uid,
                            follower_count: user.user_info.follower_count
                        }));
                    }
                    break;
            }
        } else if (caller == "fetchUserFollowers") {
            if (response.data && response.data.followers && Array.isArray(response.data.followers)) {
                results = response.data.followers.map((follower) => ({
                    id: follower.uid,
                    username: follower.unique_id,
                    full_name: follower.nickname,
                    biography: follower.signature,
                    profile_pic_url: follower.avatar_medium.url_list[0],
                    profile_pic_url_hd: follower.avatar_larger.url_list[0],
                    follower_count: follower.follower_count,
                    following_count: follower.following_count,
                    likes_count: follower.total_favorited,
                    is_verified: follower.verification_type === 1,
                }));
            } else {
                results = false;
            }
        }
        return results;
    }
}